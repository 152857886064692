@import "@runette/leaflet-fullscreen/dist/leaflet.fullscreen.css";
@import "../node_modules/leaflet.markercluster/dist/MarkerCluster.css";
@import "../node_modules/leaflet.markercluster/dist/MarkerCluster.Default.css";
@import "../node_modules/leaflet/dist/leaflet.css";
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.w-100 {
  width: 100%;
}
.h-100 {
  height: 100%;
}

d-none {
  display: none;
}

.text-center {
  text-align: center;
}

router-outlet + * {
  width: 100%;
}

.flex {
  display: flex;
}

a {
  color: white;

}

a:visited {
  color: antiquewhite;
}

.bold {
  font-weight: bold;
}

.mat-form-field {
  padding: 0.2rem 0.25rem !important;
}
.leaflet-popup-content-wrapper {
	padding: 1px;
	text-align: left;
	border-radius: 10px;
}
.leaflet-popup-content-wrapper, .leaflet-popup-tip {
  background:white;
  color: #333;
  box-shadow: 0 3px 14px rgb(0 0 0 / 40%);
}

